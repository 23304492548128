import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from './Payment/StripePaymentForm';

const stripePromise = loadStripe('your_publishable_key');

const PRESET_AMOUNTS = [
  {
    id: 'small',
    amount: 5,
    bonus: 0,
    label: '$5'
  },
  {
    id: 'medium',
    amount: 10,
    bonus: 1,
    label: '$10 + $1 bonus'
  }
];

function PaymentTab() {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [balance, setBalance] = useState(0); // This should come from your backend

  const handleAmountSelect = (preset) => {
    setSelectedAmount(preset);
    setCustomAmount('');
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      setCustomAmount(value);
      setSelectedAmount(null);
    }
  };

  const handlePaymentComplete = (payment) => {
    setBalance(prevBalance => prevBalance + payment.amount);
    setShowPaymentForm(false);
    // Additional success handling
  };

  const getSelectedValue = () => {
    if (selectedAmount) {
      return selectedAmount.amount + selectedAmount.bonus;
    }
    return customAmount ? parseFloat(customAmount) : 0;
  };

  const calculateBonus = (amount) => {
    if (amount >= 100) return amount * 0.30;
    if (amount >= 50) return amount * 0.2;
    if (amount >= 10) return amount * 0.1;
    return 0;
  };

  return (
    <div className="settings-section payment-section">
      <div className="balance-display">
        <h3>Current Balance</h3>
        <div className="balance-amount">${balance.toFixed(2)}</div>
      </div>

      <div className="top-up-section">
        <h3>Add Funds</h3>
        <p>Select an amount to add to your account</p>

        <div className="preset-amounts">
          {PRESET_AMOUNTS.map(preset => (
            <div 
              key={preset.id}
              className={`amount-card ${selectedAmount?.id === preset.id ? 'selected' : ''}`}
              onClick={() => handleAmountSelect(preset)}
            >
              <div className="amount-value">${preset.amount}</div>
              {preset.bonus > 0 && (
                <div className="amount-bonus">+${preset.bonus} bonus</div>
              )}
              <div className="amount-total">
                Total: ${(preset.amount + preset.bonus).toFixed(2)}
              </div>
            </div>
          ))}
        </div>


      { (
        <div className="payment-form-container">
         
          <Elements stripe={stripePromise}>
            <StripePaymentForm 
              amount={getSelectedValue()}
              onPaymentComplete={handlePaymentComplete}
            />
          </Elements>
        </div>
      )}

    </div>
      
    </div>
  );
}

export default PaymentTab; 