import React, { useState, useRef, useEffect } from 'react';
import { setDoc, doc, collection, query, getDocs, addDoc, deleteDoc, limit } from "firebase/firestore";
import { db, auth } from '../../firebase';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import './NotesInterface.css';
import SimilarTextPopup from './SimilarTextPopup';
import './SimilarTextPopup.css';
import { handleRAGQuery } from './NotesUtils';
import { handleGraphQuery } from './NotesUtils';
import { handleSwarmQuery, addCopyButtonsToCodeBlocks, addButtonsToAIMessages, showButtonFeedback, copyAIMessageToClipboard, saveAIMessageAsEmbedding, formatPreviewText } from './NotesUtils';

import ConversationHistory from './ConversationHistory';
import InputArea from './InputArea';
import { addReferenceHoverListeners, setHoverReference, formatAnswerWithReferences } from './NotesUtils';
import { handleGraphGeneration } from '../../utils/apiHelpers';


const API_URL = process.env.REACT_APP_API_URL || 'https://api-989064782518.europe-west1.run.app';
const NotesInterface = ({
  initialContent,
  updateWindow,
  editable,
  handlePerformPCA,
  fetchGraphData,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [chatContent, setChatContent] = useState(initialContent);
  const [selectedText, setSelectedText] = useState('');
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const textareaRef = useRef(null);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [similarTexts, setSimilarTexts] = useState([]);
  const [isRAGActive, setIsRAGActive] = useState(false);
  const [isGraphRAGActive, setIsGraphRAGActive] = useState(false);
  const [conversationReferences, setConversationReferences] = useState({});
  const [currentReferences, setCurrentReferences] = useState([]);
  const [hoverReference, setHoverReference] = useState(null);
  const [lastRefNumber, setLastRefNumber] = useState(0);

  const [popupPositions, setPopupPositions] = useState([]);
  const chatContentRef = useRef(null);

  const [isSwarmActive, setIsSwarmActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadConversations();
  }, []);

  useEffect(() => {
    if (chatContentRef.current) {
      const element = chatContentRef.current;
      const isScrolledToBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 10;
      
      if (isScrolledToBottom || isLoading) {
        element.scrollTop = element.scrollHeight;
      }
    }
    
    addCopyButtonsToCodeBlocks();
    addButtonsToAIMessages();
    addReferenceHoverListeners(currentReferences, setHoverReference);
  }, [chatContent, currentReferences, isLoading]);

  const loadConversations = async () => {
    try {
      const userConversationsRef = collection(db, `users/${auth.currentUser.uid}/conversations`);
      const q = query(userConversationsRef, limit(20));
      const querySnapshot = await getDocs(q);
      const conversationList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Sort conversations by timestamp in descending order (newest first)
      const sortedConversations = conversationList.sort((a, b) => {
        return b.timestamp.toMillis() - a.timestamp.toMillis();
      });
      
      setConversations(sortedConversations);
      if (sortedConversations.length > 0 && !activeConversation) {
        const firstConversation = sortedConversations[0];
        setActiveConversation(firstConversation.id);
        setChatContent(firstConversation.content);
        updateWindow(firstConversation.content);
        setCurrentReferences(firstConversation.references || []);
        setLastRefNumber((firstConversation.references || []).length);
      } else if (activeConversation) {
        const activeConv = sortedConversations.find(conv => conv.id === activeConversation);
        if (activeConv) {
          setCurrentReferences(activeConv.references || []);
          setLastRefNumber((activeConv.references || []).length);
        }
      }
    } catch (error) {
      console.error("Error loading conversations:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      const userMessage = `<div class="message user-message"><div class="message-content">${marked(inputValue.trim())}</div></div>`;
      const updatedContent = chatContent + userMessage;
      setChatContent(updatedContent);
      updateWindow(updatedContent);
      setIsLoading(true);

      const loadingMessage = `
        <div class="message ai-message loading-message">
          <div class="loading-dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>`;
      setChatContent(updatedContent + loadingMessage);
      updateWindow(updatedContent + loadingMessage);

      try {
        const idToken = await auth.currentUser.getIdToken();
        let response = {};
        let newReferences;
        let answer = '';

        if (isSwarmActive) {
          const swarmResponse = await handleSwarmQuery(inputValue, 20, idToken);
          answer = swarmResponse;
        } else if (isGraphRAGActive) {
          const graphRAGResult = await handleGraphQuery(inputValue, chatContent, idToken);
          answer = graphRAGResult.answer;
          const sources = graphRAGResult.sources;
          if (sources && sources.length > 0) {
            answer += '\n\nSources: ' + sources.join(', ');
          }
        } else if (isRAGActive) {
          const ragResult = await handleRAGQuery(inputValue, chatContent, idToken, lastRefNumber);
          answer = ragResult.response?.data?.answer || ragResult.response?.answer || ragResult;
          newReferences = ragResult.similarTexts;
          const updatedReferences = [...currentReferences, ...newReferences];
          setCurrentReferences(updatedReferences);
          setLastRefNumber(ragResult.newLastRefNumber);
        } else {
          response = await axios.post(`${API_URL}/query`, { 
            query: inputValue, 
            conversation_history: chatContent
          }, {
            headers: {
              'Authorization': `Bearer ${idToken}`,
              
            }
          });
          answer = response.data?.answer;
        }

        if (!answer) {
          throw new Error('No answer received from the server');
        }

        const formattedAnswer = formatAnswerWithReferences(answer, newReferences || []);
        const aiMessage = `<div class="message ai-message"><div class="message-content">${formattedAnswer}</div></div>`;
        const finalContent = updatedContent + aiMessage;

        if (activeConversation) {
          setConversations(prevConversations => {
            return prevConversations.map(conv => {
              if (conv.id === activeConversation) {
                return {
                  ...conv,
                  content: finalContent,
                  timestamp: new Date(),
                  references: isRAGActive ? [...currentReferences, ...newReferences] : currentReferences
                };
              }
              return conv;
            });
          });

          await setDoc(doc(db, `users/${auth.currentUser.uid}/conversations`, activeConversation), {
            content: finalContent,
            timestamp: new Date(),
            references: isRAGActive ? [...currentReferences, ...newReferences] : currentReferences
          }, { merge: true });
        } else {
          const userConversationsRef = collection(db, `users/${auth.currentUser.uid}/conversations`);
          const newConversationRef = await addDoc(userConversationsRef, {
            content: finalContent,
            timestamp: new Date(),
            references: isRAGActive ? [...currentReferences, ...newReferences] : currentReferences
          });

          const newConversation = {
            id: newConversationRef.id,
            content: finalContent,
            timestamp: new Date(),
            references: isRAGActive ? [...currentReferences, ...newReferences] : currentReferences
          };
          setConversations(prev => [newConversation, ...prev]);
          setActiveConversation(newConversationRef.id);
        }

        setIsLoading(false);
        setChatContent(finalContent);
        updateWindow(finalContent);

      } catch (error) {
        console.error('Error processing query:', error);
        setIsLoading(false);
        const errorMessage = '<div class="message ai-message"><div class="message-content">An error occurred while processing your request.</div></div>';
        const errorContent = updatedContent + errorMessage;
        setChatContent(errorContent);
        updateWindow(errorContent);
      }

      setInputValue('');
    }
  };

  const toggleRAG = () => {
    console.log('RAG toggle');
    setIsRAGActive(!isRAGActive);
    setIsGraphRAGActive(false);
  };

  const toggleGraphRAG = () => {
    console.log('GraphRAG toggle');
    setIsGraphRAGActive(!isGraphRAGActive);
    setIsRAGActive(false);
  };

  const toggleSwarm = () => {
    setIsSwarmActive(!isSwarmActive);
    setIsGraphRAGActive(false);
    setIsRAGActive(false);
  };

  const handleCloseSimilarText = (index) => {
    setSimilarTexts(prevTexts => prevTexts.filter((_, i) => i !== index));
  };

  const handleDeleteSimilarText = (index) => {
    handleCloseSimilarText(index);
  };

  const handleCopySimilarText = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
    });
  };

  const handleTextSelection = () => {
    const selection = window.getSelection().toString();
    setSelectedText(selection);
  };

  const handleConversationSelect = (conversationId) => {
    const selectedConversation = conversations.find(conv => conv.id === conversationId);
    if (selectedConversation) {
      setChatContent(selectedConversation.content);
      updateWindow(selectedConversation.content);
      setActiveConversation(conversationId);
      setCurrentReferences(selectedConversation.references || []);
      setLastRefNumber((selectedConversation.references || []).length);
    }
  };

  const addNewConversation = async () => {
    setChatContent('');
    updateWindow('');
  
    const userConversationsRef = collection(db, `users/${auth.currentUser.uid}/conversations`);
    const newConversationRef = await addDoc(userConversationsRef, {
      content: '',
      timestamp: new Date(),
      references: []
    });
    
    setActiveConversation(newConversationRef.id);
    setCurrentReferences([]);
    setLastRefNumber(0);
  };

  const deleteConversation = async (conversationId) => {
    try {
      setConversations(prev => prev.filter(conv => conv.id !== conversationId));
      
      if (activeConversation === conversationId) {
        const nextConversation = conversations.find(conv => conv.id !== conversationId);
        if (nextConversation) {
          setActiveConversation(nextConversation.id);
          setChatContent(nextConversation.content);
          updateWindow(nextConversation.content);
          setCurrentReferences(nextConversation.references || []);
          setLastRefNumber((nextConversation.references || []).length);
        } else {
          setChatContent('');
          updateWindow('');
          setActiveConversation(null);
          setCurrentReferences([]);
          setLastRefNumber(0);
        }
      }
      
      await deleteDoc(doc(db, `users/${auth.currentUser.uid}/conversations`, conversationId));
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };


  const handleGenerateGraph = async () => {
    const idToken = await auth.currentUser.getIdToken();

    const graphResult = await handleGraphGeneration(chatContent, idToken);
    alert('Graph generation completed successfully!');
    // fetch the graph data
    fetchGraphData();
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible(!isHistoryVisible);
  };



  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      console.log('Code copied to clipboard');
    });
  };

  const cycleMode = () => {
    // If all modes are off, turn on RAG
    if (!isRAGActive && !isGraphRAGActive && !isSwarmActive) {
      setIsRAGActive(true);
      setIsGraphRAGActive(false);
      setIsSwarmActive(false);
    }
    // If RAG is on, switch to GraphRAG
    else if (isRAGActive) {
      setIsRAGActive(false);
      setIsGraphRAGActive(true);
      setIsSwarmActive(false);
    }
    // If GraphRAG is on, switch to Swarm
    else if (isGraphRAGActive) {
      setIsRAGActive(false);
      setIsGraphRAGActive(false);
      setIsSwarmActive(true);
    }
    // If Swarm is on, turn all off
    else if (isSwarmActive) {
      setIsRAGActive(false);
      setIsGraphRAGActive(false);
      setIsSwarmActive(false);
    }
  };

  return (
    <div className="notes-interface">
      <ConversationHistory
        isVisible={isHistoryVisible}
        conversations={conversations}
        activeConversation={activeConversation}
        onSelect={handleConversationSelect}
        onDelete={deleteConversation}
        formatPreviewText={formatPreviewText}
      />
      <div className={`chat-area ${isHistoryVisible ? '' : 'expanded'}`}>
        <div 
          className="chat-content"
          onMouseUp={handleTextSelection}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chatContent) }}
          ref={chatContentRef}
        />
        <InputArea 
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSubmit={handleSubmit}
          toolbarProps={{
            isHistoryVisible,
            toggleHistoryVisibility,
            handlePerformPCA,
            fetchGraphData,
            isRAGActive,
            isGraphRAGActive,
            isSwarmActive,
            handleGenerateGraph,
            addNewConversation,
            cycleMode,
          }}
        />
        {/* <div className="similar-texts-container">
          {similarTexts.map((text, index, source) => (
            <SimilarTextPopup
              key={index}
              text={text}
              source={source}
              onClose={() => handleCloseSimilarText(index)}
              onDelete={() => handleDeleteSimilarText(index)}
              onCopy={() => handleCopySimilarText(text)}
              initialPosition={popupPositions[index]}
            />
          ))}
        </div> */}
        {hoverReference && (
          <div 
            className="reference-popup"
            style={{
              left: `${hoverReference.x}px`,
              top: `${hoverReference.y}px`
            }}
          >
            {hoverReference.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesInterface;
