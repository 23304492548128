import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDcFGWsXxThxgrU6ZizZZr_smo90oOWlsk",
  authDomain: "birdrag-e59cf.firebaseapp.com",
  projectId: "birdrag-e59cf",
  storageBucket: "birdrag-e59cf.appspot.com",
  messagingSenderId: "989064782518",
  appId: "1:989064782518:web:ed186d2c0c3a4e2f85fc17",
  measurementId: "G-74Z4BM279C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);