import React from 'react';

const ConversationHistory = ({ 
  isVisible, 
  conversations, 
  activeConversation, 
  onSelect, 
  onDelete,
  formatPreviewText 
}) => {
  return (
    <div className={`conversation-history ${isVisible ? 'visible' : 'hidden'}`}>
      <ul>
        {conversations.map(conv => (
          <li 
            key={conv.id}
            className={activeConversation === conv.id ? 'active' : ''}
            onClick={() => onSelect(conv.id)}
          >
            <span>{formatPreviewText(conv.content) || "New conversation"}</span>
            <button 
              className="delete-conversation"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(conv.id);
              }}
            >
              ×
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConversationHistory; 