import React, { useState, useEffect, Suspense, lazy } from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth } from '../../firebase';
import NotesInterface from './NotesInterface';
import { performPCAAndVisualize } from '../Vizualisation/EmbeddingVisualization';
import axios from 'axios';
import SpeechToText from '../Transcription/record';
import LightRagGraphVisualization from '../Vizualisation/LightRagGraphVisualization';
import { QuickNotesPlayground } from '../QuickNotesPlayground/QuickNotesPlayground';

const GraphVisualization = lazy(() => import('../Vizualisation/GraphVisualization'));
const EmbeddingVisualization = lazy(() => import('../Vizualisation/EmbeddingVisualization'));

const API_URL = process.env.REACT_APP_API_URL || 'https://api-989064782518.europe-west1.run.app';

const Notes = () => {
  const [chatContent, setChatContent] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [embeddingVisualizationData, setEmbeddingVisualizationData] = useState(null);
  const [showGraph, setShowGraph] = useState(false);
  const [showEmbedding, setShowEmbedding] = useState(false);
  const [isEmbeddingMinimized, setIsEmbeddingMinimized] = useState(false);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken().then(token => {
        setIdToken(token);
      });
    }
  }, []);

  const loadUserConversation = async (userId) => {
    try {
      const userNotesRef = collection(db, `users/${userId}/conversations`);
      const q = query(userNotesRef);
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        setChatContent(doc.data().content);
      }
    } catch (error) {
      console.error("Error loading user conversation:", error);
    }
  };

  const fetchGraphData = async () => {
    setIsGraphLoading(true);
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.get(`${API_URL}/graph`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      // console.log('Graph data received:', response.data);
      setGraphData(response.data);
      setShowGraph(true);
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const handleDataChange = (updatedData) => {
    setGraphData(updatedData);
  };

  const handlePerformPCA = async () => {
    if (showEmbedding) {
      // If PCA is already showing, just hide it
      setShowEmbedding(false);
      setEmbeddingVisualizationData(null);
    } else {
      // If PCA is not showing, fetch and show it
      try {
        const data = await performPCAAndVisualize();
        setEmbeddingVisualizationData(data);
        setShowEmbedding(true);
      } catch (error) {
        console.error('Error performing PCA:', error);
      }
    }
  };

  const handleCloseEmbedding = () => {
    setShowEmbedding(false);
    setEmbeddingVisualizationData(null);
  };

  const handleMinimizeEmbedding = () => {
    setIsEmbeddingMinimized(!isEmbeddingMinimized);
  };

  return (
    <div className="notes-playground">
      <div className="visualization-container">
        {showEmbedding && embeddingVisualizationData && (
          <Suspense fallback={<div>Loading embedding visualization...</div>}>
            <EmbeddingVisualization data={embeddingVisualizationData} />
          </Suspense>
        )}
        {showGraph && (
          <Suspense fallback={<div className="fullscreen-loader">Loading graph...</div>}>
            <GraphVisualization 
              data={graphData}
              isLoading={isGraphLoading}
              onDataChange={handleDataChange}
              idToken={idToken}
            />
          </Suspense>
        )}
      </div>
      
      <div className="notes-content">
        <div className="notes-interface-container">
        
          <NotesInterface
            initialContent={chatContent}
            updateWindow={setChatContent}
            editable={false}
            handlePerformPCA={handlePerformPCA}
            fetchGraphData={fetchGraphData}
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;
