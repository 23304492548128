import React, { useState, useRef, useEffect, memo } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import QuickNoteInterface from './QuickNoteInterface';
import 'react-resizable/css/styles.css';

function DraggableQuickNoteWindow({ id, content, onQuerySubmit, activeMode, onClose, onDelete, updateWindow, onFocus, zIndex, setZIndex, showSnakeGame, position = { x: 0, y: 0 } }) {
  const [minimized, setMinimized] = useState(false);
  const [size, setSize] = useState({ width: 250, height: 200 });
  const nodeRef = useRef(null);

  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  const onResize = (event, { size }) => {
    setSize({ width: size.width, height: size.height });
  };

  const handleContentChange = (newContent) => {
    updateWindow(id, newContent);
  };

  const handleFocus = () => {
    onFocus(id);
    setZIndex(id);
  };

  const handleClose = () => {
    onClose(id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const windowContent = (
    <div
      className={`chat-window ${minimized ? 'minimized' : ''}`}
      style={{ 
        width: minimized ? 220 : size.width, 
        height: minimized ? 30 : size.height,
        zIndex: zIndex,
        position: 'absolute',
      }}
      onClick={handleFocus}
    >
      <div 
        className="chat-window-header"
      >
        <div className='chat-window-header-title' style={{ opacity: 0.3 }}>{id.substring(9, 13)}</div>
        <div className='chat-window-header-buttons'>
          <button className='chat-window-minimize' onClick={handleMinimize} style={{ opacity: 0.3 }}>{minimized ? 'max' : 'min'}</button>
          <button className='chat-window-save' onClick={() => onQuerySubmit('/save')} style={{ opacity: 0.3 }}>save</button>
          <button className='chat-window-delete' onClick={() => {
            if (window.confirm('Are you sure you want to toss this note?')) {
              onDelete(id);
            }
          }} style={{ opacity: 0.3 }}>toss</button>
          <button className='chat-window-close' onClick={handleClose} style={{ opacity: 0.3 }}>x</button>
        </div>
      </div>
      {!minimized && (
        <div className="chat-window-content">
          {showSnakeGame ? (
            <div className="snake-game-container">
              {/* Snake game content */}
            </div>
          ) : (
            <QuickNoteInterface
              content={content}
              onQuerySubmit={onQuerySubmit}
              activeMode={activeMode}
              updateWindow={handleContentChange}
              editable={true}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      handle=".chat-window-header"
      bounds="parent"
      defaultPosition={position}
      onStart={handleFocus}
      scale={1}
      positionOffset={{ x: 0, y: 0 }}
      grid={[1, 1]}
      onDrag={(e, data) => {
        if (nodeRef.current) {
          nodeRef.current.style.transform = `translate(${data.x}px, ${data.y}px)`;
        }
      }}
    >
      <div ref={nodeRef} style={{ position: 'absolute', zIndex: zIndex }}>
        <div 
          className="draggable-container"
          onDrop={handleDragOver}
          onDragOver={handleDragOver}
        >
          {minimized ? (
            windowContent  
          ) : (
            <Resizable
              width={size.width}
              height={size.height}
              onResize={onResize}
              minConstraints={[220, minimized ? 30 : 200]}
              maxConstraints={[1600, 1200]}
              handle={<div className="resize-handle" />}
              resizeHandles={['se']}
            >
              {windowContent}
            </Resizable>
          )}
        </div>
      </div>
    </Draggable>
  );
}

export default memo(DraggableQuickNoteWindow, (prevProps, nextProps) => {
  return prevProps.content === nextProps.content && 
         prevProps.zIndex === nextProps.zIndex &&
         prevProps.position?.x === nextProps.position?.x &&
         prevProps.position?.y === nextProps.position?.y &&
         prevProps.minimized === nextProps.minimized;
});