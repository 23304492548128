import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './embeddingVisualization.css';
import axios from 'axios';
import { auth } from '../../firebase';

export const performPCAAndVisualize = async () => {
  try {
    const idToken = await auth.currentUser.getIdToken();
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/perform-pca', {}, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error performing PCA:', error);
    throw error;
  }
};

const EmbeddingVisualization = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    svg.selectAll("*").remove();

    const x = d3.scaleLinear()
      .domain(d3.extent(data, d => d[0]))
      .range([margin.left, width - margin.right]);

    const y = d3.scaleLinear()
      .domain(d3.extent(data, d => d[1]))
      .range([height - margin.bottom, margin.top]);

    // Create a group for the data points
    const pointGroup = svg.append("g");

    // Create a tooltip div
    const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // Create circles for each data point
    const circles = pointGroup.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", d => x(d[0]))
      .attr("cy", d => y(d[1]))
      .attr("r", 3)
      .attr("fill", "black");

    // Add event listeners to circles
    circles
      .on("mouseover", function(event, d) {
        d3.select(this).attr("r", 5).attr("fill", "grey");
        tooltip.transition()
          .duration(200)
          .style("opacity", .2);
        tooltip.html(`x: ${d[0].toFixed(4)}<br/>y: ${d[1].toFixed(4)}`)
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY - 28) + "px");
        console.log(d);
      })
      .on("mouseout", function() {
        d3.select(this).attr("r", 3).attr("fill", "black");
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // Add zoom functionality
    const zoom = d3.zoom()
      .scaleExtent([0.5, 20])
      .on("zoom", (event) => {
        pointGroup.attr("transform", event.transform);
      });

    svg.call(zoom);

  }, [data]);

  return (
    <div className="embedding-visualization-container">
      <svg ref={svgRef} className="embedding-visualization-svg"></svg>
    </div>
  );
};

export default EmbeddingVisualization;
