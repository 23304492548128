import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

const RandomWalkVisualization = ({ data, parentSvg, parentG }) => {
  const [isWalking, setIsWalking] = useState(false);
  const [walkSpeed, setWalkSpeed] = useState(1000); // milliseconds between steps
  const [currentPath, setCurrentPath] = useState([]);
  
  const startRandomWalk = (startNodeId) => {
    if (!data || !data.nodes.length) return;
    
    // If no start node specified, pick random
    const startNode = startNodeId 
      ? data.nodes.find(n => n.id === startNodeId)
      : data.nodes[Math.floor(Math.random() * data.nodes.length)];
    
    setCurrentPath([startNode.id]);
    setIsWalking(true);
  };

  const getNextNode = (currentNodeId) => {
    const links = data.links.filter(
      l => l.source.id === currentNodeId || l.target.id === currentNodeId
    );
    
    if (links.length === 0) return null;
    
    const randomLink = links[Math.floor(Math.random() * links.length)];
    return randomLink.source.id === currentNodeId 
      ? randomLink.target.id 
      : randomLink.source.id;
  };

  useEffect(() => {
    if (!isWalking || !parentSvg || !parentG) return;

    const walkInterval = setInterval(() => {
      setCurrentPath(prevPath => {
        const currentNodeId = prevPath[prevPath.length - 1];
        const nextNodeId = getNextNode(currentNodeId);
        
        if (!nextNodeId) {
          setIsWalking(false);
          return prevPath;
        }
        
        return [...prevPath, nextNodeId];
      });
    }, walkSpeed);

    return () => clearInterval(walkInterval);
  }, [isWalking, walkSpeed, data]);

  useEffect(() => {
    if (!parentG || currentPath.length < 2) return;

    // Remove previous walk visualization
    parentG.selectAll('.random-walk-path').remove();
    parentG.selectAll('.random-walk-node').remove();

    // Draw the path
    const pathData = currentPath.map(nodeId => {
      const node = data.nodes.find(n => n.id === nodeId);
      return [node.x, node.y];
    });

    const lineGenerator = d3.line();
    
    parentG.append('path')
      .attr('class', 'random-walk-path')
      .attr('d', lineGenerator(pathData))
      .attr('fill', 'none')
      .attr('stroke', '#FFA500')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5,5');

    // Highlight current node
    const currentNode = data.nodes.find(n => n.id === currentPath[currentPath.length - 1]);
    parentG.append('circle')
      .attr('class', 'random-walk-node')
      .attr('cx', currentNode.x)
      .attr('cy', currentNode.y)
      .attr('r', 8)
      .attr('fill', '#FFA500')
      .attr('stroke', '#fff')
      .attr('stroke-width', 2);
  }, [currentPath, parentG, data.nodes]);

  return (
    <div className="random-walk-controls">
      <button 
        onClick={() => startRandomWalk()}
        disabled={isWalking}
      >
        {isWalking ? 'Walking...' : 'Start Random Walk'}
      </button>
      <button 
        onClick={() => setIsWalking(false)}
        disabled={!isWalking}
      >
        Stop Walk
      </button>
      <div>
        <label>
          Speed (ms):
          <input 
            type="range" 
            min="200" 
            max="2000" 
            value={walkSpeed}
            onChange={(e) => setWalkSpeed(Number(e.target.value))}
          />
          {walkSpeed}ms
        </label>
      </div>
      {currentPath.length > 0 && (
        <div>
          Path length: {currentPath.length}
        </div>
      )}
    </div>
  );
};

export default RandomWalkVisualization; 