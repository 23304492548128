import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import './SimilarTextPopup.css';
import axios from 'axios';
import { auth } from '../../firebase';


const SimilarTextPopup = ({ text, onClose, onDelete, onCopy, initialPosition }) => {
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const rect = popupRef.current.getBoundingClientRect();
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      ref={popupRef}
      className="similar-text-popup"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    >
      <div className="similar-text-header" onMouseDown={handleMouseDown}>
        <span></span>
        <button onClick={onClose} title="Close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="similar-text-content">{text}</div>
      <div className="similar-text-buttons">
        <button onClick={onCopy} title="Copy">
          <FontAwesomeIcon icon={faCopy} />
        </button>
        <button onClick={onDelete} title="Delete">
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default SimilarTextPopup;
