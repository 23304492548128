import React, { useState, useEffect, Suspense, lazy } from 'react';
import { collection, setDoc, query, where, getDocs, doc, deleteDoc, limit } from "firebase/firestore";
import { db, auth } from '../../firebase';
import DraggableQuickNoteWindow from './DraggableQuickNoteWindow';
import QuickNoteChatUtils from './QuickNoteChatUtils';
import NotesList from './NotesList';
import axios from 'axios';
import { handleGraphGeneration } from '../../utils/apiHelpers';
import { performPCAAndVisualize } from '../Vizualisation/EmbeddingVisualization';
import './window.css';
import './chat.css';
import './buttons.css';
import DOMPurify from 'dompurify';

const GraphVisualization = lazy(() => import('../Vizualisation/GraphVisualization'));
const EmbeddingVisualization = lazy(() => import('../Vizualisation/EmbeddingVisualization'));

const API_URL = process.env.REACT_APP_API_URL || 'https://api-989064782518.europe-west1.run.app';

export const QuickNotesPlayground = () => {
  const [notes, setNotes] = useState([]);
  const [windowedNotes, setWindowedNotes] = useState(new Set());
  const [windowPositions, setWindowPositions] = useState({});
  const [highestZIndex, setHighestZIndex] = useState(1);
  const [graphData, setGraphData] = useState(null);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [embeddingVisualizationData, setEmbeddingVisualizationData] = useState(null);
  const [isCentered, setIsCentered] = useState(true);
  const [lastSavedContent, setLastSavedContent] = useState({});

  const [GraphVisualization, setGraphVisualization] = useState(null);
  const [EmbeddingVisualization, setEmbeddingVisualization] = useState(null);

  const [idToken, setIdToken] = useState(null);

  const [isDraggingOverList, setIsDraggingOverList] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      auth.currentUser.getIdToken().then(token => {
        setIdToken(token);
      });
      loadUserConversations(auth.currentUser.uid);
    }
  }, []);

  useEffect(() => {
    setIsCentered(notes.length === 0 && !graphData && !embeddingVisualizationData);
  }, [notes, graphData, embeddingVisualizationData]);

  const loadUserConversations = async (userId) => {
    try {
      const q = query(collection(db, `users/${userId}/notes`), limit(30));
      const querySnapshot = await getDocs(q);
      const loadedNotes = querySnapshot.docs.map(doc => ({
        id: doc.id,
        content: doc.data().content,
      }));
      
      // Initialize lastSavedContent with loaded content
      const savedContent = {};
      loadedNotes.forEach(note => {
        savedContent[note.id] = note.content;
      });
      setLastSavedContent(savedContent);
      
      // Set the notes for the list
      setNotes(loadedNotes);
      setHighestZIndex(prev => prev + loadedNotes.length);
    } catch (error) {
      console.error("Error loading user notes:", error);
    }
  };

  const createNewNote = async (content = '') => {
    const noteId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const newNote = {
      id: noteId,
      content: content || '',
      zIndex: highestZIndex + 1
    };
    
    setNotes(prev => [...prev, newNote]);
    setHighestZIndex(prev => prev + 1);

    // // Save to database
    // try {
    //   await setDoc(doc(db, `users/${auth.currentUser.uid}/notes`, noteId), {
    //     id: noteId,
    //     content: content || '',
    //     timestamp: new Date()
    //   });
    // } catch (error) {
    //   console.error('Error creating new note:', error);
    // }
  };

  const toggleNoteWindow = async (noteId, position = null) => {
    if (windowedNotes.has(noteId)) {
      setWindowedNotes(prev => {
        const newWindowed = new Set(prev);
        newWindowed.delete(noteId);
        return newWindowed;
      });
      
      const note = notes.find(n => n.id === noteId);
      if (note && note.content !== lastSavedContent[noteId]) {
        await saveNote(noteId, note.content);
      }
    } else {
      setWindowedNotes(prev => {
        const newWindowed = new Set(prev);
        newWindowed.add(noteId);
        if (position) {
          setWindowPositions(prev => ({
            ...prev,
            [noteId]: position
          }));
        }
        return newWindowed;
      });
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const noteData = JSON.parse(e.dataTransfer.getData('note'));
    const pos = {
      x: e.clientX - 125,
      y: e.clientY - 100
    };
    
    toggleNoteWindow(noteData.id, pos);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const deleteQuickNoteWindow = async (id) => {
    try {
        // Update local state first for instant feedback
        setNotes(prev => prev.filter(note => note.id !== id));
        setWindowedNotes(prev => new Set(prev.filter(noteId => noteId !== id)));
        setWindowPositions(prev => Object.fromEntries(Object.entries(prev).filter(([key, value]) => key !== id)));

        // Then handle the backend operations
        await deleteDoc(doc(db, `users/${auth.currentUser.uid}/notes`, id));
        
        const idToken = await auth.currentUser.getIdToken();
        await axios.post(`${API_URL}/delete-embedding`, 
            { window_id: id },
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`
                }
            }
        );
    } catch (error) {
        console.error('Error deleting note:', error);
        // Optionally revert the state if deletion fails
        loadUserConversations(auth.currentUser.uid);
    }
  };

  const updateNote = (id, updates) => {
    setNotes(prev => {
      const noteIndex = prev.findIndex(note => note.id === id);
      if (noteIndex === -1) return prev;
      
      const newNotes = [...prev];
      newNotes[noteIndex] = { ...newNotes[noteIndex], ...updates };
      return newNotes;
    });
  };

  const handleQuerySubmit = async (query, windowId) => {
    if (!windowId) {
      console.error('No windowId provided');
      return;
    }

    const window = notes.find(w => w.id === windowId);
    if (!window) {
      console.error(`Window with id ${windowId} not found`);
      return;
    }

    const trimmedQuery = query.trim().toLowerCase();

    if (trimmedQuery.startsWith('/')) {
      const command = trimmedQuery.slice(1);
      if (command.startsWith('q ')) {
        const queryText = command.slice(2);
        await handleQueryCommand(queryText, windowId);
      } else {
        executeCommand(command, windowId);
      }
      return;
    }

    const updatedContent = window.content + `\n${query} + \n`;
    updateNote(windowId, { content: updatedContent });

    saveNote(windowId);

    
  };

  const saveNote = async (windowId, content) => {
    if (content === undefined) {
      const note = notes.find(n => n.id === windowId);
      if (!note) return;
      content = note.content;
    }

    try {
      await setDoc(doc(db, `users/${auth.currentUser.uid}/notes`, windowId), {
        id: windowId,
        content: content,
        timestamp: new Date()
      });

      setLastSavedContent(prev => ({
        ...prev,
        [windowId]: content
      }));

      updateNote(windowId, {
        content: content
      });

      const idToken = await auth.currentUser.getIdToken();
      await axios.post(`${API_URL}/save-embedding`, 
        { 
          text: content, 
          window_id: windowId, 
          source: 'quicknotes' 
        },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        }
      );

    } catch (error) {
      console.error('Error saving note:', error);
      updateNote(windowId, {
        content: content + '\nError: Failed to save note.'
      });
    }
  };

  const handleQueryCommand = async (queryText, windowId) => {
    const window = notes.find(w => w.id === windowId);
    if (!window) {
      console.error(`Window with id ${windowId} not found`);
      return;
    }
    // add a new line to the content
    const newContent = window.content + '\n';
    updateNote(windowId, { content: newContent });

    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(`${API_URL}/query`, { 
        query: queryText, 
        conversation_history: window.content
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });

      const { answer } = response.data
      const newContent = window.content + '\n' + answer + '\n' + '/q ';

      updateNote(windowId, { content: newContent });
      
      await setDoc(doc(db, `users/${auth.currentUser.uid}/notes`, windowId), {
        id: windowId,
        content: newContent,
        timestamp: new Date()
      });

    } catch (error) {
      console.error('Error processing query:', error);
      updateNote(windowId, {
        content: window.content + '\nError: An error occurred while processing your request.'
      });
    }
  };

  const { executeCommand } = QuickNoteChatUtils({  
    handleCommand: (command, windowId, additionalData) => {
      const window = notes.find(w => w.id === windowId);
      if (!window) {
        console.error(`Window with id ${windowId} not found in handleCommand`);
        return;
      }
      updateNote(windowId, { content: window.content + `\nExecuting command: ${command}` });
      
      let content = window.content;
      switch (command) {
        case 'help':
          content += `\n${additionalData}`;
          break;
        case 'perform-pca':
          handlePerformPCA()
          break;
        case 'graph':
          fetchGraphData();
          break;
        case 'logout':
          auth.signOut();
          break;
        case 'save':
          saveNote(windowId, content);
          break;
        case 'find-similar':
          findSimilar(content, windowId);
          break;
        case 'newchat':
          createNewNote();
          break;
        case 'updategraph':
          handleGenerateGraph(content);
          break;
        default:
          content += `\nUnknown command: ${command}`;
          break;         

      }
    }
  });

  const handleGenerateGraph = async (content) => {
    const idToken = await auth.currentUser.getIdToken();
    // purify the chat content
    const graphResult = await handleGraphGeneration(content, idToken);
    alert('Graph generation completed successfully!');
    // fetch the graph data
    fetchGraphData();
  };

  const fetchGraphData = async () => {
    setIsGraphLoading(true);
    try {
      await loadGraphVisualization();
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.get(`${API_URL}/graph`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setGraphData(response.data);
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const handleDataChange = (updatedData) => {
    setGraphData(updatedData);
  };

  const saveChatWindow = async (content, windowId) => {

    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(`${API_URL}/save-embedding`, { text: content, window_id: windowId, source: 'quicknotes' }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      console.log('Embedding saved:', response.data);
    } catch (error) {
      console.error('Error saving embedding:', error);
    }
  };


  const handlePerformPCA = async () => {
    try {
      await loadEmbeddingVisualization();
      const data = await performPCAAndVisualize();
    } catch (error) {
      console.error('Error performing PCA:', error);
    }
  };
  

  const findSimilar = async (content, windowId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await axios.post(`${API_URL}/find-similar`, { query: content, limit: 5 }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      for (const embedding of response.data) {
        createNewNote(embedding.text);
      }
    } catch (error) {
      console.error('Error finding similar:', error);
    }
  };

  const loadGraphVisualization = async () => {
    if (!GraphVisualization) {
      const module = await import('../Vizualisation/GraphVisualization');
      setGraphVisualization(() => module.default);
    }
  };

  const loadEmbeddingVisualization = async () => {
    if (!EmbeddingVisualization) {
      const module = await import('../Vizualisation/EmbeddingVisualization');
      setEmbeddingVisualization(() => module.default);
    }
  };

  const handleDeleteSelected = async (selectedIds) => {
    try {
      // Update local state first for instant feedback
      setNotes(prev => prev.filter(note => !selectedIds.includes(note.id)));
      
      // For Set, create new Set excluding selected IDs
      setWindowedNotes(prev => {
        const newSet = new Set(prev);
        selectedIds.forEach(id => newSet.delete(id));
        return newSet;
      });
      
      // For positions object
      setWindowPositions(prev => {
        const newPositions = { ...prev };
        selectedIds.forEach(id => delete newPositions[id]);
        return newPositions;
      });

      // Then handle the backend operations
      await Promise.all(selectedIds.map(async (id) => {
        await deleteDoc(doc(db, `users/${auth.currentUser.uid}/notes`, id));
        
        const idToken = await auth.currentUser.getIdToken();
        await axios.post(`${API_URL}/delete-embedding`, 
          { window_id: id },
          {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          }
        );
      }));
    } catch (error) {
      console.error('Error deleting selected notes:', error);
      // Optionally revert the state if deletion fails
      loadUserConversations(auth.currentUser.uid);
    }
  };

  // Get notes that aren't displayed as windows
  const getListNotes = () => {
    return notes.filter(note => !windowedNotes.has(note.id));
  };

  // Get notes that are displayed as windows
  const getWindowNotes = () => {
    return notes.filter(note => windowedNotes.has(note.id));
  };

  const bringToFront = (id) => {
    const newZIndex = highestZIndex + 1;
    setHighestZIndex(newZIndex);
    setNotes(prevNotes =>
      prevNotes.map(note =>
        note.id === id ? { ...note, zIndex: newZIndex } : note
      )
    );
  };

  return (
    <div className="chat-window-manager" style={{ display: 'flex', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }}>
      <div 
        className={`notes-list-container ${isDraggingOverList ? 'drag-over' : ''}`}
        onDrop={(e) => {
          e.preventDefault();
          const noteData = JSON.parse(e.dataTransfer.getData('note'));
          toggleNoteWindow(noteData.id);
          setIsDraggingOverList(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDraggingOverList(true);
        }}
        onDragLeave={() => setIsDraggingOverList(false)}
      >
        <NotesList 
          notes={getListNotes()}
          onDragStart={(e, note) => {
            e.dataTransfer.setData('note', JSON.stringify(note));
          }}
          onDeleteSelected={handleDeleteSelected}
          onNewNote={() => createNewNote()}
        />
      </div>
      <div 
        className="windows-container" 
        style={{ flex: 1, position: 'relative' }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {getWindowNotes().map(note => (
          <DraggableQuickNoteWindow 
            key={note.id}
            id={note.id}
            content={note.content}
            onQuerySubmit={(query) => handleQuerySubmit(query, note.id)}
            onClose={() => toggleNoteWindow(note.id)}
            onDelete={() => deleteQuickNoteWindow(note.id)}
            updateWindow={(id, newContent) => updateNote(id, { content: newContent })}
            onFocus={() => bringToFront(note.id)}
            zIndex={note.zIndex}
            position={windowPositions[note.id]}
            setZIndex={bringToFront}
          />
        ))}
        {graphData && !embeddingVisualizationData && GraphVisualization && (
          <Suspense fallback={<div>Loading graph...</div>}>
            <GraphVisualization 
              data={graphData}
              isLoading={isGraphLoading}
              onDataChange={handleDataChange}
              idToken={idToken}
            />
          </Suspense>
        )}
        {embeddingVisualizationData && EmbeddingVisualization && (
          <Suspense fallback={<div>Loading embedding visualization...</div>}>
            <EmbeddingVisualization data={embeddingVisualizationData} />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default QuickNotesPlayground;
