import React, { useState, useEffect, useRef } from 'react';

const NUM_PLANES = 50  ;
const NEIGHBOR_RADIUS = 40;
const MAX_SPEED = 3;
const MIN_SPEED = 1;
const MOUSE_AVOIDANCE_RADIUS = 100; // Increased from 100 to 1000
const MOUSE_FORCE = 2;
const UPWARD_FORCE = 0.001;  // Force pulling planes upward
const LEFTWARD_FORCE = 0.001;  // Force pulling planes left
const PREFERRED_HEIGHT = window.innerHeight * 0.3;  // Prefer flying in top 30% of screen

const PaperPlane = ({ targetRef, isHovered }) => {
  const [planes, setPlanes] = useState(() => 
    Array.from({ length: NUM_PLANES }, () => ({
      position: { 
        x: Math.random() * window.innerWidth, 
        y: Math.random() * window.innerHeight 
      },
      velocity: { 
        x: (Math.random() - 0.5) * 4, 
        y: (Math.random() - 0.5) * 4 
      },
      rotation: 0
    }))
  );
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const animationFrameRef = useRef();
  const bounds = { width: window.innerWidth, height: window.innerHeight };

  // Add mouse movement listener
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const limitSpeed = (velocity) => {
    const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);
    if (speed > MAX_SPEED) {
      return {
        x: (velocity.x / speed) * MAX_SPEED,
        y: (velocity.y / speed) * MAX_SPEED
      };
    }
    if (speed < MIN_SPEED) {
      return {
        x: (velocity.x / speed) * MIN_SPEED,
        y: (velocity.y / speed) * MIN_SPEED
      };
    }
    return velocity;
  };

  const calculateMouseAvoidance = (position) => {
    const dx = position.x - mousePos.x;
    const dy = position.y - mousePos.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    if (distance < MOUSE_AVOIDANCE_RADIUS) {
      // Calculate avoidance force (stronger when closer)
      const force = (1 - distance / MOUSE_AVOIDANCE_RADIUS) * MOUSE_FORCE;
      return {
        x: (dx / distance) * force,
        y: (dy / distance) * force
      };
    }
    return { x: 0, y: 0 };
  };

  const getNeighbors = (plane, index) => {
    return planes.filter((other, otherIndex) => {
      if (index === otherIndex) return false;
      const dx = other.position.x - plane.position.x;
      const dy = other.position.y - plane.position.y;
      return Math.sqrt(dx * dx + dy * dy) < NEIGHBOR_RADIUS;
    });
  };

  const calculateFlockingBehavior = (plane, index) => {
    const neighbors = getNeighbors(plane, index);
    if (neighbors.length === 0) return { x: 0, y: 0 };

    // Cohesion
    const centerOfMass = neighbors.reduce((acc, curr) => ({
      x: acc.x + curr.position.x,
      y: acc.y + curr.position.y
    }), { x: 0, y: 0 });
    
    const cohesion = {
      x: (centerOfMass.x / neighbors.length - plane.position.x) * 0.005,
      y: (centerOfMass.y / neighbors.length - plane.position.y) * 0.005
    };

    // Alignment
    const averageVelocity = neighbors.reduce((acc, curr) => ({
      x: acc.x + curr.velocity.x,
      y: acc.y + curr.velocity.y
    }), { x: 0, y: 0 });
    
    const alignment = {
      x: (averageVelocity.x / neighbors.length) * 0.05,
      y: (averageVelocity.y / neighbors.length) * 0.05
    };

    // Separation
    const separation = neighbors.reduce((acc, curr) => {
      const dx = plane.position.x - curr.position.x;
      const dy = plane.position.y - curr.position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      return {
        x: acc.x + (dx / (distance * distance)) * 0.5,
        y: acc.y + (dy / (distance * distance)) * 0.5
      };
    }, { x: 0, y: 0 });

    return {
      x: cohesion.x + alignment.x + separation.x,
      y: cohesion.y + alignment.y + separation.y
    };
  };

  useEffect(() => {
    const updatePositions = () => {
      setPlanes(prevPlanes => {
        const newPlanes = prevPlanes.map((plane, index) => {
          let newVelocity = { ...plane.velocity };
          
          // Apply flocking behavior
          const flockingForce = calculateFlockingBehavior(plane, index);
          newVelocity.x += flockingForce.x;
          newVelocity.y += flockingForce.y;

          // Apply mouse avoidance
          const mouseForce = calculateMouseAvoidance(plane.position);
          newVelocity.x += mouseForce.x;
          newVelocity.y += mouseForce.y;

          // Add leftward force
          newVelocity.x -= LEFTWARD_FORCE;

          // Add upward force based on distance from preferred height
          const heightDiff = plane.position.y - PREFERRED_HEIGHT;
          newVelocity.y -= (heightDiff * UPWARD_FORCE);

          // If hovering, make closest planes move towards target
          if (isHovered && targetRef.current) {
            const targetRect = targetRef.current.getBoundingClientRect();
            const targetX = targetRect.left - 30;
            const targetY = targetRect.top + targetRect.height / 2;
            
            const dx = targetX - plane.position.x;
            const dy = targetY - plane.position.y;
            
            // Only affect 20 closest planes
            const closestPlanes = planes
              .map((p, i) => ({ 
                distance: Math.sqrt(
                  Math.pow(targetX - p.position.x, 2) + 
                  Math.pow(targetY - p.position.y, 2)
                ), 
                index: i 
              }))
              .sort((a, b) => a.distance - b.distance)
              .slice(0, 20)
              .map(p => p.index);

            if (closestPlanes.includes(index)) {
              newVelocity.x += dx * 0.05;
              newVelocity.y += dy * 0.05;
            }
          }

          // Limit speed
          newVelocity = limitSpeed(newVelocity);

          // Update position
          let newPosition = {
            x: plane.position.x + newVelocity.x,
            y: plane.position.y + newVelocity.y
          };

          // Modify wrap-around behavior to spawn on right when going off left
          if (newPosition.x < -20) newPosition.x = bounds.width + 20;
          if (newPosition.x > bounds.width + 20) newPosition.x = -20;
          if (newPosition.y < 0) newPosition.y = bounds.height;
          if (newPosition.y > bounds.height) newPosition.y = 0;

          // Calculate rotation based on velocity
          const rotation = (Math.atan2(newVelocity.y, newVelocity.x) * (180 / Math.PI)) + 90;

          return {
            position: newPosition,
            velocity: newVelocity,
            rotation
          };
        });

        return newPlanes;
      });

      animationFrameRef.current = requestAnimationFrame(updatePositions);
    };

    animationFrameRef.current = requestAnimationFrame(updatePositions);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isHovered, targetRef, mousePos]);

  return (
    <>
      {planes.map((plane, index) => (
        <div
          key={index}
          style={{
            position: 'fixed',
            left: `${plane.position.x}px`,
            top: `${plane.position.y}px`,
            transform: `rotate(${plane.rotation}deg)`,
            transition: 'transform 0.2s ease',
            opacity: 0.3,
            pointerEvents: 'none',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{ 
              width: '4px',
              height: '4px',
              backgroundColor: '#FF1493',
              borderRadius: '50%'
            }}
          />
        </div>
      ))}
    </>
  );
};

export default PaperPlane;