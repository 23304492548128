import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './GraphVisualization.css';
import { deleteNodes } from '../../utils/apiHelpers';
import RandomWalkVisualization from './RandomWalkVisualization';


const GraphVisualization = ({ data, isLoading, onDataChange, idToken }) => {
  const svgRef = useRef(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedNodesStats, setSelectedNodesStats] = useState({
    totalConnections: 0,
    averageConnections: 0,
    types: {}
  });
  const [gRef, setGRef] = useState(null);
  const [lastClickedNode, setLastClickedNode] = useState(null);

  useEffect(() => {
    if (!idToken) {
      console.warn('No authentication token provided to GraphVisualization');
    }
  }, [idToken]);

  useEffect(() => {
    if (!data || isLoading) return;

    console.log('Rendering graph with data:', data);

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous rendering

    const width = window.innerWidth;
    const height = window.innerHeight;

    svg.attr("width", width).attr("height", height);

    // Create a group for the zoom functionality
    const g = svg.append("g");
    setGRef(g); // Store the g element reference

    // Add zoom behavior
    const zoom = d3.zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
        updateLabelsVisibility(event.transform.k);
      });

    svg.call(zoom);

    const simulation = d3.forceSimulation(data.nodes)
      .force("link", d3.forceLink(data.links).id(d => d.id))
      .force("charge", d3.forceManyBody().strength(-100))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collide", d3.forceCollide().radius(3))
      .force("x", d3.forceX(width / 2).strength(0.1))
      .force("y", d3.forceY(height / 2).strength(0.1));

    const link = g.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(data.links)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value) * 0.5);

    const node = g.append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 0.5)
      .selectAll("circle")
      .data(data.nodes)
      .join("circle")
      .attr("r", 5)
      .attr("fill", "#FF1493")  // Changed to match the theme's pink
      .call(drag(simulation));

    // Add node labels
    const nodeLabels = g.append("g")
      .selectAll("text")
      .data(data.nodes)
      .join("text")
      .text(d => d.id)
      .attr("class", "node-label")
      .attr("visibility", "hidden")
      .style("font-family", "'Lucida Console', monospace")
      .style("font-size", "3px");

    // Add relationship labels
    const linkLabels = g.append("g")
      .selectAll("text")
      .data(data.links)
      .join("text")
      .text(d => d.type || '')
      .attr("class", "link-label")
      .attr("visibility", "hidden")
      .style("font-family", "'Lucida Console', monospace")
      .style("font-size", "2px");

    console.log('data.links', data.links);

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);

      nodeLabels
        .attr("x", d => d.x + 8)
        .attr("y", d => d.y + 3);

      linkLabels
        .attr("x", d => (d.source.x + d.target.x) / 2)
        .attr("y", d => (d.source.y + d.target.y) / 2);
    });

    function updateLabelsVisibility(scale) {
      const labelsVisible = scale > 1.5;
      nodeLabels.attr("visibility", labelsVisible ? "visible" : "hidden")
                .attr("font-size", `${Math.min(12 / scale, 6)}px`);
      linkLabels.attr("visibility", labelsVisible ? "visible" : "hidden")
                .attr("font-size", `${Math.min(10 / scale, 3)}px`);
    }

    // Add click event to nodes
    node.on("click", (event, d) => {
      handleNodeClick(event, d);
    })
    .on("dblclick", handleNodeDoubleClick);

    // Add background click handler
    svg.on("click", (event) => {
      if (event.target === svgRef.current) {
        setSelectedNodes([]);
        setLastClickedNode(null);
        setSelectedNodesStats({
          totalConnections: 0,
          averageConnections: 0,
          types: {}
        });
        
        // Reset all node colors
        d3.select(svgRef.current)
          .selectAll("circle")
          .attr("fill", "#FF1493");
      }
    });

  }, [data, isLoading]);

  const drag = (simulation) => {
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
    
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
    
    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const calculateSelectedNodesStats = (nodeIds) => {
    const stats = {
      totalConnections: 0,
      averageConnections: 0,
      types: {}
    };

    if (nodeIds.length === 0) return stats;

    // Count connections for selected nodes
    data.links.forEach(link => {
      if (nodeIds.includes(link.source.id) || nodeIds.includes(link.target.id)) {
        stats.totalConnections++;
        // Count relationship types
        const type = link.type || 'unknown';
        stats.types[type] = (stats.types[type] || 0) + 1;
      }
    });

    stats.averageConnections = stats.totalConnections / nodeIds.length;

    return stats;
  };

  const handleNodeClick = (event, d) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the SVG
    const isSelected = selectedNodes.includes(d.id);
    const newSelectedNodes = isSelected 
      ? selectedNodes.filter(id => id !== d.id)
      : [...selectedNodes, d.id];
    
    setSelectedNodes(newSelectedNodes);
    setLastClickedNode(d);
    setSelectedNodesStats(calculateSelectedNodesStats(newSelectedNodes));

    d3.select(svgRef.current)
      .selectAll("circle")
      .attr("fill", node => 
        newSelectedNodes.includes(node.id) ? "#333333" : "#FF1493"
      );
  };

  const handleDeleteNodes = async () => {
    if (selectedNodes.length === 0) return;

    try {
      if (!idToken) {
        alert('You need to be logged in to delete nodes. Please log in and try again.');
        console.error('No authentication token available');
        return;
      }

      console.log('Attempting to delete nodes with token:', idToken?.substring(0, 10) + '...');
      
      // Update the visualization immediately before the API call
      const updatedNodes = data.nodes.filter(node => !selectedNodes.includes(node.id));
      const updatedLinks = data.links.filter(link => 
        !selectedNodes.includes(link.source.id) && !selectedNodes.includes(link.target.id)
      );

      // Update the visual elements immediately
      const svg = d3.select(svgRef.current);
      const g = svg.select("g");
      
      // Update nodes
      const nodes = g.selectAll("circle")
        .data(updatedNodes, d => d.id);
      nodes.exit().remove();
      
      // Update links
      const links = g.selectAll("line")
        .data(updatedLinks, d => `${d.source.id}-${d.target.id}`);
      links.exit().remove();
      
      // Update labels
      g.selectAll(".node-label")
        .data(updatedNodes, d => d.id)
        .exit()
        .remove();
        
      g.selectAll(".link-label")
        .data(updatedLinks, d => `${d.source.id}-${d.target.id}`)
        .exit()
        .remove();

      // Update data reference
      data.nodes = updatedNodes;
      data.links = updatedLinks;

      // Clear selection state
      setSelectedNodes([]);
      setSelectedNodesStats({
        totalConnections: 0,
        averageConnections: 0,
        types: {}
      });

      // Make the API call after updating the visualization
      await deleteNodes(selectedNodes, idToken);

    } catch (error) {
      console.error('Error deleting nodes:', error);
      alert('Failed to delete nodes. Please check your login status and try again.');
    }
  };

  const handleNodeDoubleClick = (event, d) => {
    event.stopPropagation();
    const connectedNodeIds = new Set();
    data.links.forEach(link => {
      if (link.source.id === d.id) {
        connectedNodeIds.add(link.target.id);
      } else if (link.target.id === d.id) {
        connectedNodeIds.add(link.source.id);
      }
    });
    connectedNodeIds.add(d.id);

    const newSelectedNodes = Array.from(connectedNodeIds);
    setSelectedNodes(newSelectedNodes);
    setSelectedNodesStats(calculateSelectedNodesStats(newSelectedNodes));
    
    d3.select(svgRef.current)
      .selectAll("circle")
      .attr("fill", node => connectedNodeIds.has(node.id) ? "red" : "#FF69B4");
  };

  const findStrayNodes = () => {
    const connectedNodes = new Set();
    data.links.forEach(link => {
      connectedNodes.add(link.source.id);
      connectedNodes.add(link.target.id);
    });

    const strayNodes = data.nodes
      .filter(node => !connectedNodes.has(node.id))
      .map(node => node.id);

    setSelectedNodes(strayNodes);
    setSelectedNodesStats(calculateSelectedNodesStats(strayNodes));
    
    d3.select(svgRef.current)
      .selectAll("circle")
      .attr("fill", node => strayNodes.includes(node.id) ? "red" : "#FF69B4");
  };

  return (
    <div className="graph-visualization">
      {isLoading ? (
        <div>Loading graph...</div>
      ) : (
        <>
          <svg ref={svgRef}></svg>
          {selectedNodes.length > 0 && (
            <div className="bottom-controls">
              <button 
                className="delete-nodes-button" 
                onClick={handleDeleteNodes}
              >
                Delete Selected ({selectedNodes.length})
              </button>
              <div className="stats-summary">
                {selectedNodes.length} nodes selected • {selectedNodesStats.totalConnections} connections • 
                {Object.entries(selectedNodesStats.types).map(([type, count], index, arr) => (
                  <span key={type}>
                    {` ${count} ${type}${index < arr.length - 1 ? ',' : ''}`}
                  </span>
                ))}
              </div>
              {lastClickedNode?.description && (
                <div className="node-description">
                  {lastClickedNode.description}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GraphVisualization;
