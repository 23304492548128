import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ToolbarButtons from './ToolbarButtons';

const InputArea = ({ 
  inputValue, 
  setInputValue, 
  handleSubmit,
  toolbarProps
}) => {
  return (
    <form onSubmit={handleSubmit} className="input-area">
          <ToolbarButtons 
            {...toolbarProps}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        <div className="input-field-container">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            className="input-field"
          />
          <button type="submit" className="send-button">
            <FontAwesomeIcon icon={faPaperPlane} style={{ transform: 'rotate(30deg)', opacity: '0.6' }} />
        </button>
        </div>
    </form>
  );
};

export default InputArea; 