import React, { useState } from 'react';
import './NotesList.css';

function NotesList({ notes, onDragStart, onDeleteSelected, onNewNote }) {
  const [selectedNotes, setSelectedNotes] = useState(new Set());

  const toggleNoteSelection = (noteId, e) => {
    e.stopPropagation();
    setSelectedNotes(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(noteId)) {
        newSelected.delete(noteId);
      } else {
        newSelected.add(noteId);
      }
      return newSelected;
    });
  };

  const handleDeleteSelected = () => {
    if (selectedNotes.size > 0) {
      const confirmMessage = `Are you sure you want to delete ${selectedNotes.size} selected note${selectedNotes.size > 1 ? 's' : ''}?`;
      if (window.confirm(confirmMessage)) {
        onDeleteSelected(Array.from(selectedNotes));
        setSelectedNotes(new Set());
      }
    }
  };

  return (
    <div className="notes-list">
      <div className="notes-list-header">
        <button className="new-note-button" onClick={onNewNote}>+</button>
        {selectedNotes.size > 0 && (
          <button 
            className="delete-selected-button"
            onClick={handleDeleteSelected}
          >
            Delete Selected ({selectedNotes.size})
          </button>
        )}
      </div>
      <div className="notes-items">
        {notes.map((note) => (
          <div
            key={note.id}
            className={`note-item ${selectedNotes.has(note.id) ? 'selected' : ''}`}
            draggable
            onDragStart={(e) => onDragStart(e, note)}
          >
            <div className="note-item-content">
              <div className="note-preview">
                {note.content ? note.content.substring(0, 50) + '...' : 'New note...'}
              </div>
              <button
                className={`select-button ${selectedNotes.has(note.id) ? 'selected' : ''}`}
                onClick={(e) => toggleNoteSelection(note.id, e)}
              >
                {selectedNotes.has(note.id) ? '✓' : ''}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotesList; 