// frontend/src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import ChatBot from './components/ChatBot/ChatBot';
import Forum from './components/Forum/Forum';

function App() {
    return (
        <div className="App">
          <Home />
          {/* <ChatBot /> */}


        </div>
    );
}

export default App;
