import React, { useState, useRef, useEffect } from 'react';

function QuickNoteInterface({ content, onQuerySubmit, activeMode, updateWindow, editable }) {
  const [localContent, setLocalContent] = useState(content);
  const textareaRef = useRef(null);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const cursorPosition = textareaRef.current.selectionStart;
      const contentBeforeCursor = localContent.substring(0, cursorPosition);
      const contentAfterCursor = localContent.substring(cursorPosition);
      const linesBeforeCursor = contentBeforeCursor.split('\n');
      const currentLine = linesBeforeCursor[linesBeforeCursor.length - 1].trim();

      if (currentLine.startsWith('/')) {
        handleCommand(currentLine);
      } else {
        setLocalContent(prevContent => {
          return contentBeforeCursor + '\n' + contentAfterCursor;
        });
        setTimeout(() => {
          textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition + 1;
        }, 0);
      }
    }
  };

  const handleCommand = (command) => {
    onQuerySubmit(command);
  };

  const handleContentChange = (event) => {
    setLocalContent(event.target.value);
    updateWindow(event.target.value);
  };

  return (
    <div className="chat-interface">
      <div className="chat-output">
        <textarea
          ref={textareaRef}
          value={localContent}
          onChange={handleContentChange}
          onKeyDown={handleKeyDown}
          className="content-textarea"
          readOnly={!editable}
        />
      </div>
    </div>
  );
}

export default QuickNoteInterface;
