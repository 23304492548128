import React from 'react';
import axios from 'axios';
import { marked } from 'marked';
import ReactDOM from 'react-dom/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faSave, faCheck } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';



const commands = [
  { name: 'summary', description: 'Summarizes the conversation history.' },
  { name: 'clear', description: 'Clears the conversation history.' },
  { name: 'help', description: 'Displays available commands and modes.' },
  { name: 'updategraph', description: 'Updates the memory store with the current conversation history.' },
  { name: 'logout', description: 'Logs out the user.' },
  { name: 'q', description: 'Enables interaction with the LLM.' },
  { name: 'newchat', description: 'Starts a new chat.' },
  { name: 'find-similar', description: 'Finds similar embeddings to the query.' },
  { name: 'save', description: 'Saves an embedding to the database.' },
  { name: 'perform-pca', description: 'Performs PCA on the embeddings and visualizes them.' },

];

function ChatUtils({ handleCommand }) {
  const executeCommand = (command, windowId) => {
    if (!windowId) {
      console.error('No windowId provided to executeCommand');
      return;
    }

    switch (command) {
      case 'q':
      case 'note':
      case 'graph':
      case 'updategraph':
      case 'summary':
      case 'clear':
      case 'logout':
      case 'newchat':
      case 'find-similar':
      case 'perform-pca':
      case 'save':
      case 'snake':
        handleCommand(command, windowId);
        break;
      case 'help':
        const helpText = `
Available commands:
${commands.map(cmd => `- ${cmd.name}: ${cmd.description}`).join('\n')}
`;
        handleCommand('help', windowId, helpText);
        break;
      default:
        console.log(`Unknown command: ${command}`);
    }
  };

  return {
    executeCommand,
    commands,
  };
}

export default ChatUtils;



export const handleRAGQuery = async (query, conversationHistory, idToken, lastRefNumber = 0) => {
  try {
    const similarResponse = await axios.post('https://api-989064782518.europe-west1.run.app/find-similar', { query, limit: 3 }, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });

    const similarTexts = similarResponse.data.map((embedding, index) => ({
      text: embedding.text,
      refNumber: lastRefNumber + index + 1
    }));

    const formattedQuery = `
      Context information:
      ${similarTexts.map(item => `[${item.refNumber}] ${item.text}`).join('\n\n')}

      User query: ${query}

      Please use the context information above to help answer the user's query. If the context is relevant, include reference numbers like [1], [2], etc. in your response to cite the sources. If the context is not relevant, you can ignore it.
    `;
    console.log('formattedQuery', formattedQuery);
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/query', { 
      query: formattedQuery, 
      conversation_history: conversationHistory
    }, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });

    const newLastRefNumber = lastRefNumber + similarTexts.length;

    return { response, similarTexts, newLastRefNumber };
  } catch (error) {
    console.error('Error in RAG query:', error);
    throw error;
  }
};


export const handleGraphQuery = async (query, conversationHistory, idToken) => {
  try {
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/rag-query', {
      query: query,
      conversation_history: conversationHistory,
      mode: 'graph'
    }, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    
    // Return both answer and sources from the response
    return {
      answer: response.data.answer,
      sources: response.data.sources
    };
  } catch (error) {
    console.error('Error in graph query:', error);
    throw error;
  }
};

export const handleSwarmQuery = async (query, maxEmails, idToken) => {
  try {
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/swarm-query', 
      { 
        message: query,
        max_emails: maxEmails 
      }, 
      {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      }
    );
    return response.data.response;
  } catch (error) {
    console.error('Error in Swarm query:', error);
    throw error;
  }
};



export const formatPreviewText = (content) => {
  const strippedContent = content.replace(/<[^>]+>/g, '');
  const cleanContent = strippedContent.replace(/(User:|AI:)/g, '').trim();
  return cleanContent.length > 30 ? cleanContent.slice(0, 30) + '...' : cleanContent;
};

export const formatAnswerWithReferences = (answer, newRefs) => {
  let formattedAnswer = marked(answer);
  newRefs.forEach((ref) => {
    const refRegex = new RegExp(`\\[${ref.refNumber}\\]`, 'g');
    formattedAnswer = formattedAnswer.replace(refRegex, `<span class="reference" data-ref="${ref.refNumber}">[${ref.refNumber}]</span>`);
  });
  return formattedAnswer;
};

export const addCopyButtonsToCodeBlocks = () => {
  const codeBlocks = document.querySelectorAll('pre code');
  codeBlocks.forEach((codeBlock) => {
    if (!codeBlock.parentNode.querySelector('.copy-button')) {
      const button = document.createElement('button');
      button.innerHTML = '+'; 
      button.className = 'copy-button';
      button.onclick = (e) => {
        e.stopPropagation();
        copyCodeToClipboard(codeBlock.textContent);
        const originalText = button.innerHTML;
        button.innerHTML = '✓';
        button.style.color = 'green';
        
        setTimeout(() => {
          button.innerHTML = originalText;
          button.style.color = '';
        }, 2000);
      };
      codeBlock.parentNode.style.position = 'relative';
      codeBlock.parentNode.appendChild(button);
    }
  });
};

export const addButtonsToAIMessages = () => {
  const aiMessages = document.querySelectorAll('.ai-message');
  aiMessages.forEach((message) => {
    if (!message.querySelector('.ai-message-buttons-container')) {
      const buttonBar = document.createElement('div');
      buttonBar.className = 'ai-message-buttons-container';

      const copyButton = document.createElement('button');
      copyButton.className = 'ai-message-button';
      copyButton.title = 'Copy';

      const saveButton = document.createElement('button');
      saveButton.className = 'ai-message-button';
      saveButton.title = 'Save';

      buttonBar.appendChild(copyButton);
      buttonBar.appendChild(saveButton);
      message.appendChild(buttonBar);

      const copyRoot = ReactDOM.createRoot(copyButton);
      const saveRoot = ReactDOM.createRoot(saveButton);

      copyRoot.render(
        <FontAwesomeIcon 
          icon={faCopy} 
          style={{ opacity: '0.6', backgroundColor: 'transparent', fontSize: '14px' }} 
        />
      );
      saveRoot.render(
        <FontAwesomeIcon 
          icon={faSave} 
          style={{ opacity: '0.6', backgroundColor: 'transparent', fontSize: '14px' }} 
        />
      );

      copyButton.onclick = (e) => {
        e.stopPropagation();
        copyAIMessageToClipboard(message);
        showButtonFeedback(copyRoot, 'copy');
      };

      saveButton.onclick = (e) => {
        e.stopPropagation();
        saveAIMessageAsEmbedding(message);
        showButtonFeedback(saveRoot, 'save');
      };
    }
  });
};

export const showButtonFeedback = (root, type) => {
  root.render(<FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />);
  
  setTimeout(() => {
    root.render(
      <FontAwesomeIcon 
        icon={type === 'copy' ? faCopy : faSave} 
        style={{ opacity: '0.6', backgroundColor: 'transparent', fontSize: '14px' }} 
      />
    );
  }, 2000);
};

export const copyAIMessageToClipboard = (messageElement) => {
  const messageContent = messageElement.querySelector('.message-content').textContent;
  navigator.clipboard.writeText(messageContent).then(() => {
    console.log('AI message copied to clipboard');
  });
};

export const saveAIMessageAsEmbedding = async (messageElement, windowId) => {
  try {
    const messageContent = messageElement.querySelector('.message-content').textContent;
    const idToken = await auth.currentUser.getIdToken();
    const uniqueId = uuidv4();
    const requestBody = {
      text: messageContent,
      source: 'AI-message',
      window_id: windowId || 'AI-Conversation ' + uniqueId
    };

    console.log('Sending save embedding request:', requestBody);

    const response = await axios.post('/save-embedding', requestBody, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    console.log('AI message saved as embedding:', response.data);
  } catch (error) {
    console.error('Error saving AI message as embedding:', error);
    console.error('Error details:', error.response?.data);
  }
};

export const copyCodeToClipboard = (code) => {
  navigator.clipboard.writeText(code).then(() => {
  });
};


export const createReferenceHandlers = (currentReferences, setHoverReference) => {
  const handleReferenceHover = (event) => {
    const refNumber = event.target.dataset.ref;
    const reference = currentReferences.find(ref => ref.refNumber === parseInt(refNumber));
    if (reference) {
      const rect = event.target.getBoundingClientRect();
      setHoverReference({
        text: reference.text,
        x: rect.right + 10,
        y: rect.top
      });
    }
  };

  const handleReferenceLeave = () => {
    setHoverReference(null);
  };

  return { handleReferenceHover, handleReferenceLeave };
};

export const addReferenceHoverListeners = (currentReferences, setHoverReference) => {
  const chatContent = document.querySelector('.chat-content');
  if (chatContent) {
    const references = chatContent.querySelectorAll('.reference');
    const { handleReferenceHover, handleReferenceLeave } = createReferenceHandlers(currentReferences, setHoverReference);
    
    references.forEach(ref => {
      ref.addEventListener('mouseover', handleReferenceHover);
      ref.addEventListener('mouseout', handleReferenceLeave);
    });
  }
};
