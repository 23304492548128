import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowLeft, 
  faArrowRight, 
  faProjectDiagram,
  faBook,
  faShareAlt,
  faRobot,
  faPlus,
  faNetworkWired,
  faDiagramProject 
} from '@fortawesome/free-solid-svg-icons';

// Add new CSS for the animation
const styles = `
  @keyframes glisten {
    0% { color: #ff69b4; }
    50% { color: #ff1493; }
    100% { color: #ff69b4; }
  }

  .active-mode-icon {
    animation: glisten 2s infinite;
  }
`;

const ToolbarButtons = ({
  isHistoryVisible,
  toggleHistoryVisibility,
  handlePerformPCA,
  fetchGraphData,
  isRAGActive,
  isGraphRAGActive,
  isSwarmActive,
  handleGenerateGraph,
  addNewConversation,
  cycleMode // New prop for handling mode cycling
}) => {
  // Helper function to determine which icon to show
  const getModeIcon = () => {
    if (isRAGActive) return faBook;
    if (isGraphRAGActive) return faShareAlt;
    if (isSwarmActive) return faRobot;
    return faBook; // Default icon when off
  };

  // Helper function to determine if any mode is active
  const isModeActive = isRAGActive || isGraphRAGActive || isSwarmActive;

  return (
    <>
      <style>{styles}</style>
      <div className="interface-buttons">
        <button type="button" onClick={toggleHistoryVisibility}>
          {isHistoryVisible ? 
            <FontAwesomeIcon icon={faArrowLeft} title='Hide History' /> : 
            <FontAwesomeIcon icon={faArrowRight} title='Show History' />}
        </button>

        {/* New combined mode button */}
        <button type="button" onClick={cycleMode} title="Change mode">
          <FontAwesomeIcon 
            icon={getModeIcon()} 
            className={isModeActive ? 'active-mode-icon' : ''} 
            style={isModeActive ? { color: '#ff69b4' } : { opacity: '0.5', color: 'grey' }}
          />
        </button>

        {/* Updated graph generation buttons with new icons */}
        <button type="button" onClick={fetchGraphData} title="View Knowledge Graph">
          <FontAwesomeIcon icon={faNetworkWired} />
        </button>
        <button type="button" onClick={handleGenerateGraph} title="Generate New Graph">
          <FontAwesomeIcon icon={faDiagramProject} />
        </button>

        <button type="button" onClick={addNewConversation} title="Start New Conversation">
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </>
  );
};

export default ToolbarButtons;