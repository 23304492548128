import React from 'react';



const commands = [
  { name: 'summary', description: 'Summarizes the conversation history.' },
  { name: 'clear', description: 'Clears the conversation history.' },
  { name: 'help', description: 'Displays available commands and modes.' },
  { name: 'updategraph', description: 'Updates the memory store with the current conversation history.' },
  { name: 'logout', description: 'Logs out the user.' },
  { name: 'q', description: 'Enables interaction with the LLM.' },
  { name: 'newchat', description: 'Starts a new chat.' },
  { name: 'find-similar', description: 'Finds similar embeddings to the query.' },
  { name: 'save', description: 'Saves an embedding to the database.' },
  { name: 'perform-pca', description: 'Performs PCA on the embeddings and visualizes them.' },

];

function QuickNoteChatUtils({ handleCommand }) {
  const executeCommand = (command, windowId) => {
    if (!windowId) {
      console.error('No windowId provided to executeCommand');
      return;
    }

    switch (command) {
      case 'q':
      case 'note':
      case 'graph':
      case 'updategraph':
      case 'summary':
      case 'clear':
      case 'logout':
      case 'newchat':
      case 'find-similar':
      case 'perform-pca':
      case 'save':
      case 'snake':
        handleCommand(command, windowId);
        break;
      case 'help':
        const helpText = `
Available commands:
${commands.map(cmd => `- ${cmd.name}: ${cmd.description}`).join('\n')}
`;
        handleCommand('help', windowId, helpText);
        break;
      default:
        console.log(`Unknown command: ${command}`);
    }
  };

  return {
    executeCommand,
    commands,
  };
}

export default QuickNoteChatUtils;
